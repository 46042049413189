@font-face {
    font-family: 'Beckett';
    src: local('Beckett'), url(../fonts/beckett.ttf) format('truetype');
}
@font-face {
    font-family: 'Bank Gothic';
    src: local('Bank Gothic'), url(../fonts/bankgothic_regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Pricedown';
    src: local('Pricedown'), url(../fonts/pricedown.ttf) format('truetype');
}
@font-face {
    font-family: 'Aharoni';
    src: local('Aharoni'), url(../fonts/aharoni.ttf) format('truetype');
}
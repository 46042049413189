@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/*=== COLOR VARIABLES ===*/
$black100: #0D0D0D;
$black75: #161616;
$white100: #FBFFFF;
$grey: #5C698F;
$yellow: #C99328;

/*=== FONT VARIABLES ===*/
$fontTitle: 'Beckett';
$fontBody: 'Aharoni';
$fontAccent: 'Bank Gothic';

/*=== SIZE VARIABLES ===*/
$screenWidth: 500px;

@mixin fontHeaderStyle {
  font-family:$fontTitle;
  font-weight:normal;
  text-decoration:none;
}
@mixin fontAccentStyle {
  font-family:$fontAccent;
  font-weight:normal;
  letter-spacing:1px;
}
@mixin fontBodyStyle{
  font-family:$fontBody;
  line-height:1.25em;
}

/*=== SPACING ===*/
$gutter: 15px;

/*=== UTILITIES ===*/
@mixin icon($url){
  content: "";
  background:url($url);
  background-repeat:no-repeat;
  background-size:contain;
  display:block;
}

html,body, #root{
  min-height:100%;
  height:100%;
  font-size:18px;
  margin:0 auto;
  position:relative;
  background-color:$black100;
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing:border-box;
}
.App {
  height:100%;
  text-align: center;
  background-color:$black100;
  color:$white100;
}

.wf-inactive .loader-fonts, .wf-active .loader-fonts{
  display:none;
}
.app-loading{
  display:flex !important;
}

/*=== UTILITIES ===*/
.btn{
  font-family:'Bank Gothic';
  background:none;
  border:none;
  color:$grey;
  text-decoration: none;
  font-size:1.5em;
  &:hover{
    color:white;
  }
  &:focus{
    outline:none;
  }
}
.fieldgroup{
  label[data-checked="true"]{
    color:white;
  }
}
ul{
  padding:0;
  margin:0;
  li{
    list-style:none;
    margin-bottom:5px;
  }
}

/*=== FONTS ===*/
*{
  @include fontBodyStyle();
}
h1,h2,h3,h4,h5,h6,p{
  text-align:left;
}
h1{
  font-size:2.5em;
}
h1,h2{
  @include fontHeaderStyle();
  text-align:left;
}
h3{
  @include fontAccentStyle();
  
}
.text-left{
  text-align:left;
}

/*=== NAV ===*/
nav{
  position:fixed;
  bottom:0;
  width:100%;
  max-width:100%;
  background-color:$black75;
  ul{
    list-style:none;
    display:flex;
    li{
      width:50%;
      a{
        text-decoration: none;
        color:$grey;
        font-family: 'Bank Gothic';
        font-size:1.25em;
        text-transform:uppercase;
        &:hover,&.active{
          color:$white100;
        }
      }
    }
  }
}

/*=== PROFILE ===*/
.profile{
  img{
    width: 150px;
    object-fit: cover;
    border-radius: 100px;
    height: 150px;
  }
}

/*=== ATTRIBUTE ===*/
.attribute{
  display:flex;
  align-items: center;
  margin:10px 0;
  p{
    font-family:'Aharoni';
    font-weight:bold;
    font-size:1.25em;
    width:50%;
    text-align:left;
  }
  div{
    width:50%;
    background-color:$grey;
    height:13px;
    span{
      display:block;
      background-color:$white100;
      height:100%;
    }
  }
}

/*=== LOCATION ===*/
.location{
  .btn--back{
    padding:15px;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
  }
  .location__image-wrapper{
    position:relative;
    img{
      height: 500px;
      width: 100%;
      object-fit: cover;
    }
    .location__image-overlay{
      position: absolute;
      bottom: 0;
      width: 100%;
      top: 0;
      background: linear-gradient(0deg, rgba($black100, 1) 0%,rgba($black100, 0.98) 5%, rgba($black100,0.3) 50%, rgba($black100, 0.3) 80%, rgba($black100,0.90) 100%);
    }
  }
  .location__content{
    position:relative;
    bottom:60px;
  }
  .location__cons{
    margin-top:$gutter*2;
    margin-bottom:$gutter*4;
  }
  .location__facilities{
    margin-top:25px;
    h3{
      margin-bottom:$gutter;
    }
    li{
      font-size:1.25em;
      display:flex;
      justify-content:space-between;
      align-items:center;
      text-align:left;
      &:after{
        @include icon('../assets/checkmark.svg');
        height:15px;
        width:25px;
        margin-right:$gutter;
      }
    }
  }
}

/*=== MAP ===*/
$markerSize: 50px;
$popupSize: 125px;
@keyframes jumpIn{
  0%{
    transform:scale(0)
  }
  25%{
    transform:scale(1.1);
  }
  100%{
    transform:scale(1);
  }
}
.map__marker-wrapper{
  display:flex;
  justify-content: center;
  position: relative;
  bottom:$markerSize/2;
  right:$markerSize/2;
  height:$markerSize;
}
.map__marker-modal{
  min-width:300px;
  position: absolute;
  z-index:2;
  top:calc(-135% - #{$popupSize});
  background-color: #0D0D0D;
  padding:$gutter;
  padding-bottom:$gutter*3;
  border-radius:$gutter/2;

  h2{
    font-size:3em;
    margin-right:$gutter*6;
    margin-bottom:$gutter;
  }
  h3{
    font-size:1.5em;
  }
  ul{
    display:flex;
    flex-wrap:wrap;
    margin-top:$gutter/2;
    li{
      margin-right:15px;
      display:flex;
      align-items:center;
      font-size:1.25em;
      margin-right:$gutter;

      &:after{
        @include icon('../assets/checkmark.svg');
        height:15px;
        width:15px;
        margin-left:5px;
      }
    }
  }

  .btn--close{
    position:absolute;
    top:0;
    right:0;
    padding:$gutter;
  }
  .btn--enter{
    position:absolute;
    bottom:0;
    right:0;
    padding:$gutter;
  }
  
}
.map__marker{
  height:$markerSize;
  width:$markerSize;
  position:absolute;
  transform:scale(1);
  animation:jumpIn 0.5s ease-out;
  img{
    width:100%;
  }
}